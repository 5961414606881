import React from "react";
import { DictImgEventCentre } from "@contracts";
import { GolfIcon } from "@components";

import "blocks/ImgEventCentre/components/EventCentreHeader/EventCentreHeader.less";

type EventCentreHeaderProps = {
    dict: DictImgEventCentre
}
const EventCentreHeader: React.FC<EventCentreHeaderProps> = ({ dict }) => (
    <header className="img-event-centre__header">
        <GolfIcon />
        <h2 className="img-event-centre__header-title">
            {dict.headerTitle}
        </h2>
        <span className="img-event-centre__header-live-text">
            {dict.headerLiveText}
        </span>
    </header>
)

export { EventCentreHeader };
